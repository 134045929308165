import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Section from "../components/Section";
import { Grid } from "semantic-ui-react";
import { 
  HeaderH1, 
  Letter, 
  LetterLogo,
  LogoHiddenLabel, 
  ResponsiveContainer, 
  ResponsiveGrid,
  SectionH2,
  SectionP,
  PartnerLogo
} from "../helpers/styledComponents";
import blacklogo from '../../static/mediaLogos/civic-alliance-logo-black.png';

const HeaderContent = (
  <ResponsiveContainer tighten="true">
    <HeaderH1  style={{ paddingBottom: "20px", marginBottom: ".5em" }}>
      Joint Statement for an Orderly and Peaceful Transition of Power
    </HeaderH1>
  </ResponsiveContainer>
)

export default ({ data }) => {
  return (
    <Layout 
      mode="dark" 
      headerContent={HeaderContent}
      title="Peaceful Transition | Civic Alliance"
    >
      <Section mode="light" extraMargin>
        <Letter>
          <LetterLogo src={blacklogo} style={{ margin: "0 auto 2em" }}/>
          <SectionP right>January 7, 2021</SectionP>
          <SectionP>The attack on the U.S. Capitol represents an attack on American democracy, a dangerous break from our democratic tradition, and must be rejected.</SectionP>
          <SectionP>There is no doubt about the integrity of the 2020 election. We reaffirm that the election result was determined by the will of the voters, certified by every state, and upheld by the courts. President-elect Joe Biden and Vice President-elect Kamala Harris were duly elected in the manner established by the U.S. Constitution, and they will be inaugurated at noon on January 20, 2021. We commend those members of Congress who upheld their constitutional duty and certified the results.</SectionP>
          <SectionP>An orderly and peaceful transition of power is a hallmark of a functioning democracy. Any attempts to incite violence or otherwise thwart a peaceful transition in the coming days cannot be tolerated. Today, we call on all Americans – including the current Administration – to accept the will of voters, abide by the rule of law, and support an orderly and peaceful transfer of power without delay. As we look ahead, we will deepen our efforts to protect our democracy, build trust in our elections, and strengthen our nation’s civic resilience for generations to come.</SectionP>
          <Section mode="light" relaxedPadding>
            <SectionH2>Signatories</SectionH2>
            <ResponsiveGrid centered negativeMargin>
              { data.allContentfulCivicAllianceCompany.nodes
                  .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0))
                  .map(company => 
                    <Grid.Column textAlign="center" verticalAlign="middle" key={company.id} title={company.name}>
                      <LogoHiddenLabel>{company.name}</LogoHiddenLabel>
                      <PartnerLogo src={company.logo.file.url} logosize={company.logoSize} />
                    </Grid.Column>
                  )
              }
            </ResponsiveGrid>
          </Section>
        </Letter>
      </Section>
    </Layout>
  );
};


export const query = graphql`
  query {
    allContentfulCivicAllianceCompany(filter: {commitments: {in: "Transition Signatory"}, logo: {file: {fileName: {ne: null}}}}, sort: {fields: name}) {
      nodes {
        name
        id
        commitments
        logoSize
        logo {
          file {
            fileName
            url
          }
        }
      }
    }
  }
`;